body {
  width: 100%;
  height: 100%;
}

#root {
  position: relative;
  display: grid;
  grid-template-rows: 64px auto 64px;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 32px 0;
  overflow-y: auto;
}

.sign-in-segment {
  position: sticky;
  top: 10vh;
  width: 320px;
}

.main-segment {
  padding: 32px !important;
  width: 640px !important;
}

.todo-item-view {
  display: grid;
  grid-template-columns: auto 4fr 1fr 1fr;
  grid-gap: 8px;
  align-items: center;
}

.todo-check.ui.checkbox input:checked~label:after {
  color: #21ba45;
}

.todo-item {
  cursor: pointer;
  color: #000000;
  display: inline;
}

.item-completed {
  text-decoration: line-through;
  font-style: italic;
  font-weight: 400 !important;
  color: #909090 !important;
}

.sign-out-btn {
  position: absolute;
  top: 32px;
  right: 32px;
}
